import { I } from './globals'
export const INPUT_EMPTY_WARNING = I('You can not leave the field empty.')
export const TXT_AUTHORIZATION = I('Authorization')
export const TXT_AUTHORIZED_OPTION = I('Authorized {AUTHORIZATION_OPTION}')
export const TXT_CLOSE_RECEIPT_EMAIL_INFO = I('Receipts will be sent from this email address. If you leave this field blank the area reply to address will be used.')
export const TXT_INSERTING = I('inserting')
export const TXT_NO_ACCESS_TOKEN = I('No access token')
export const TXT_NO_ID_TOKEN = I('No valid id token')
export const TXT_REMOVING = I('removing')
export const emptyArray = []
export const emptyObject = {}
export const doNothing = () => {}
export const idNameSmall = { id: 'id', name: 'name' }
export const emptyNormalized = { byId: emptyObject, allIds: emptyArray }
export const identity = data => data

export const PC_RECEIPT_GREETING = true

export const showNewReceipt = () => PC_RECEIPT_GREETING

export const DEFAULT_FEEDBACK_FONT = 'Verdana'

export const feedbackFontFamilies = [
  { id: 'Arial', value: I('Arial') },
  { id: 'Arial black', value: I('Arial black') },
  { id: 'Calibri', value: I('Calibri') },
  { id: 'Comic Sans MS', value: I('Comic Sans MS') },
  { id: DEFAULT_FEEDBACK_FONT, value: I('Verdana (Default)') },
  { id: 'Courier New', value: I('Courier New') },
  { id: 'Georgia', value: I('Georgia') },
  { id: 'Lucida Console', value: I('Lucida Console') },
  { id: 'Lucida Sans Unicode', value: I('Lucida Sans Unicode') },
  { id: 'Tahoma', value: I('Tahoma') },
  { id: 'Times New Roman', value: I('Times New Roman') },
  { id: 'Trebuchet MS', value: I('Trebuchet MS') }
]

export const DEFAULT_FEEDBACK_FONT_COLOR = 'Black'

export const feedbackFontColors = [
  { id: DEFAULT_FEEDBACK_FONT_COLOR, value: I('Black (Default)') },
  { id: 'Gray', value: I('Gray') },
  { id: 'Blue', value: I('Blue') },
  { id: 'Cyan', value: I('Cyan') },
  { id: 'White', value: I('White') },
  { id: 'Green', value: I('Green') },
  { id: 'Brown', value: I('Brown') },
  { id: 'Yellow', value: I('Yellow') },
  { id: 'Orange', value: I('Orange') },
  { id: 'Red', value: I('Red') },
  { id: 'Purple', value: I('Purple') },
  { id: 'Pink', value: I('Pink') }
]

export const DEFAULT_FEEDBACK_FONT_STYLE = 'Normal'

export const feedbackFontStyles = [
  { id: DEFAULT_FEEDBACK_FONT_STYLE, value: I('Normal (Default)') },
  { id: 'Italic', value: I('Italic') },
  { id: 'Bold', value: I('Bold') }
]

export const DEFAULT_FEEDBACK_FONT_SIZE = 10

export const feedbackFontSizes = [
  { id: 8, value: 8 },
  { id: 9, value: 9 },
  { id: DEFAULT_FEEDBACK_FONT_SIZE, value: I('10 (Default)') },
  { id: 11, value: 11 },
  { id: 12, value: 12 },
  { id: 14, value: 14 },
  { id: 16, value: 16 },
  { id: 18, value: 18 },
  { id: 20, value: 20 },
  { id: 22, value: 22 },
  { id: 24, value: 24 },
  { id: 26, value: 26 },
  { id: 28, value: 28 },
  { id: 36, value: 36 },
  { id: 48, value: 48 }
]

export const DEFAULT_FEEDBACK_OUTGOING_POSITION = 0

export const feedbackOutgoingPosition = [
  { id: DEFAULT_FEEDBACK_OUTGOING_POSITION, value: I('Above the salutation') },
  { id: 1, value: I('Below the salutation') },
  { id: 2, value: I('Above the signature') },
  { id: 3, value: I('Below the signature') },
  { id: 4, value: I('Below the banner') }
]

export const DEFAULT_SATISFACTION_RATING_TEXT = '☆'

export const FEEDBACK_NO_ACTION = 0

export const FEEDBACK_THANK_YOU_ACTION = 2

export const feedbackActions = [
  { id: FEEDBACK_NO_ACTION, value: I('No action') },
  { id: 1, value: I('External') },
  { id: FEEDBACK_THANK_YOU_ACTION, value: I('Thank you') },
  { id: 3, value: I('Contact me') }
]

export const noYesOptions = [
  { id: 0, value: I('No') },
  { id: 1, value: I('Yes') }
]

export const customDefaultOptions = [
  { id: 0, value: I('Custom') },
  { id: 1, value: I('Default') }
]

// TODO: pull from backend
export const AUTH_MICROSOFT_OAUTH2 = 0
export const AUTH_GOOGLE_OAUTH2 = 1
export const AUTH_MICROSOFT_OAUTH2_API = 2
export const AUTH_BASIC = 3
export const authorizationOption = {
  [AUTH_MICROSOFT_OAUTH2]: I('Microsoft OAuth2'),
  [AUTH_GOOGLE_OAUTH2]: I('Google OAuth2'),
  [AUTH_MICROSOFT_OAUTH2_API]: I('Microsoft OAuth2'),
  [AUTH_BASIC]: I('Basic'),
}

const createNormalizedAuthorization = allIds => {
  return { byId: authorizationOption, allIds }
}

export const smtpAuthorizationOption = createNormalizedAuthorization([
  AUTH_MICROSOFT_OAUTH2,
  AUTH_GOOGLE_OAUTH2,
  AUTH_BASIC
])

export const ewsAuthorizationOption = createNormalizedAuthorization([
  AUTH_MICROSOFT_OAUTH2
])

export const testMoreThanOneIMAP = false

const imapProviders = [
  AUTH_GOOGLE_OAUTH2
]

if (testMoreThanOneIMAP) {
  imapProviders.push(AUTH_MICROSOFT_OAUTH2)
}

export const imapAuthorizationOption = createNormalizedAuthorization(
  imapProviders
)
const msGraphProvider = [
  AUTH_MICROSOFT_OAUTH2_API
];
export const msGraphAuthorizationOption = createNormalizedAuthorization(
  msGraphProvider
)

export const IDTYPE_DOMAIN = 'DOMAIN'
export const IDTYPE_EMAIL = 'EMAIL_ADDRESS'

// Admin Edit Operand
export const AEO_ADD = 'add'
export const AEO_DELETE = 'del'
export const AEO_EDIT = 'edit'
export const AEO_MANUAL = 'manual'
