// handle all path prefix and space or space prefix. Not just v5 but any
// version.
export let spacePrefix = "";

// TODO: v5 page is meant for login should NOT handle /s/<space>. Check
// CCC-3560.
//NOTE(mujib): workspace here failed before calling the API of server variables
if (typeof workspace !== 'undefined' && workspace) {
	spacePrefix = "/s/" + workspace;
}

export const hasPrefix = (src, prefix) => src.lastIndexOf(prefix, 0) >= 0;

function hasSpacePrefixURLBase(src) {
	if (spacePrefix && hasPrefix(src, spacePrefix)) {
		return true;
	}
	return false;
}

function hasSpacePrefixURL() {
	return hasSpacePrefixURLBase(window.location.pathname);
}

let urlPath = `${process.env.PATH_PREFIX}v5`;

if (hasSpacePrefixURL()) {
	urlPath = spacePrefix + urlPath;
}

export const CHATBOTS = 'chatbots';

export const RECEIVE_RECEIPTS = 'receive-receipts';

export const CLOSE_RECEIPTS = 'close-receipts';

export const FEEDBACKS = 'feedbacks';

export const GREETINGS = 'greetings';

export const SMTP = 'smtp';

const receiptGreetingTabs = `(${RECEIVE_RECEIPTS}|${CLOSE_RECEIPTS}|${FEEDBACKS}|${GREETINGS})`.replace('-', '\\-');

export const V5 = urlPath
	, ADMIN = `${V5}/admin`
	, ADMIN_CHATBOT_SELECT = `${ADMIN}/${CHATBOTS}/:id?`
	, ADMIN_CHATBOT_EDIT = `${ADMIN_CHATBOT_SELECT}/edit/:eid`
	, ADMIN_CHATBOTS_INTENT = `${ADMIN_CHATBOT_EDIT}/intents/:iid`
	, ADMIN_RECEIPT_GREETINGS_SELECT = `${ADMIN}/${receiptGreetingTabs}`
	, ADMIN_RECEIPT_GREETINGS_EDIT = `${ADMIN_RECEIPT_GREETINGS_SELECT}/edit/${receiptGreetingTabs}/:id`
	, ADMIN_SMTP = `${ADMIN}/${SMTP}/:id?`
	, ADMIN_SMTP_AWSSES = `${ADMIN}/${SMTP}/:id/awsses/:region/:identities?`
	, BACKSEARCH = `${V5}/backsearch`
	, EXPORTS = `${V5}/exports`
	, EXPORTS_CALL = `${V5}/call-exports`
	, EXTERNAL_CHAT = `${V5}/external-chat`
	, EXTERNAL_OPEN = `${V5}/external-open`
	, MESSAGES = `${V5}/messages`
	, REVIEW = `${V5}/review`
	, REVIEW_ERRAND = `${REVIEW}/:cipherKey/:id`
	, SEARCH = `${V5}/search`
	, SEARCH_ANSWERS = `${V5}/search-answers`
	, STATISTICS = `${V5}/statistics`
	, WORKFLOW = `${V5}/workflow`
	, LIVE_REPORTS = `${V5}/live-reports`
	, KNOWLEDGE_BASES = `${ADMIN}/knowledge-bases`
	, KNOWLEDGE_BASES_ID = `${KNOWLEDGE_BASES}/:id`
	, KNOWLEDGE_BASES_CATEGORY_ID = `${KNOWLEDGE_BASES}/category/:id`
	, KNOWLEDGE_BASES_QUESTION_ID = `${KNOWLEDGE_BASES}/question/:id`
	, KNOWLEDGE_BASES_SUGGESTION_ID = `${KNOWLEDGE_BASES}/suggestion/:id`
	;
