import { I } from './globals'

export const TXT_ACCESS_KEY_ID = I('Access key ID')
export const TXT_ACCESS_KEY = I('access key')
export const TXT_ACCESS_KEYS = I('Access keys')
export const TXT_ADD_NEW = I('Add new')
export const TXT_AGENT_ID_ACCESS_KEY_ID = I("agent's ({AGENT ID}) access key {ACCESS KEY ID}")
export const TXT_ACCESS_KEY_EXPIRY = I('Access key expiry date {DATE}')
export const TXT_CREATE = I('Create')
export const TXT_CREATING_ACCESS_KEY = I('creating access key')
export const TXT_DISABLED = I('Disabled')
export const TXT_DKIM_TOKENS = I('DKIM CNAME')
export const TXT_EXPIRY_DATE = I('Expiration date')
export const TXT_NO_EXPIRY = I('No expiry')
export const TXT_IDENTITY = I('Identity')
export const TXT_IDENTITY_TYPE = I('Identity type')
export const TXT_SAVE = I('Save')
export const TXT_STATUS = I('Status')
export const TXT_VERIFIED = I('Verified')
