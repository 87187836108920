/* globals CKEDITOR, $, I, L, cflag, webserverRoot, orgArea, orgCompleteListsArea */
// NOTE: this should be the only place that use eslint globals escape.
// Play nice with global variables. So, all linters can work better.
import jQuery from 'jquery'
import 'ckeditor-core'
import update, { extend } from 'immutability-helper'

extend(
  '$pushx',
  (value, object) => update(object || [], { $push: value })
)

export var CKEDITOR =  window.CKEDITOR;
export var $ =  jQuery;
export var I = s => s;
export var L = s => s;
var cflag = {}; 
cflag.IsActive = function( flag ){
  var c = cflag
  , chunks
  , i
  , v
  ;
  console.log('flag >>>>>> global= ',flag);
  if ( !flag ) {
    return false;
  }
  chunks = flag.split(".");
  for (i=0; i<chunks.length; i++) {
    v = c[chunks[i]];
    switch (typeof v) {
    case 'object':
      c = v;
      break;
    case 'boolean':
      return v;
    default:
      return false;
    }
  }
  return false;
}
//Note(all): global.cflag is set in the cflags.html template file
export var cflag = global.cflag || cflag;
export var webserverRoot = window.location.protocol + "//" +window.location.hostname + process.env.PATH_PREFIX + "Cention/web/";;
export var orgArea = window.orgArea;
export var  orgCompleteListsArea = window.orgCompleteListsArea;