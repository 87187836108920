// this helpers.js can be used by both v5 and old page. Please make this helper
// as clean as posibble without import any unnecessary libraries especial
// framework libraries that can be dragged into this file.
import update from 'immutability-helper';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import {
	hasSuffix as _hasSuffix
	, trimSlash as _trimSlash
} from './webpack-public-path';
import { hasPrefix as _hasPrefix, V5 } from './path';
import { emptyArray, emptyObject } from './constants';
import { I } from '../common/v5/config'; 

// make cookie from document doc with name cName expire. Refer to:
// https://stackoverflow.com/a/4168965/1778714
export function expireCookie(doc, cName) {
	doc.cookie = encodeURIComponent(cName) + "=deleted; expires=" + new Date(0).toUTCString();
}

// get cookie with name cName from document doc. Refer to:
// https://stackoverflow.com/a/4168965/1778714
export function getCookie(doc, cName) {
	let parts = doc.cookie.split(cName + "=");
	if (parts.length == 2) return parts.pop().split(";").shift();
}

// // https://stackoverflow.com/a/18358056/1778714
// export function roundToOne(num) {
// 	return +(Math.round(num + "e+1")  + "e-1");
// }

// https://gist.github.com/LeverOne/1308368
export const uuidv4 = (a,b) => {for(b=a='';a++<36;b+=a*51&52?(a^15?8^Math.random()*(a^20?16:4):4).toString(16):'-');return b}

export const hasPrefix = _hasPrefix;

export const hasSuffix = _hasSuffix;

// TODO: since chat code had been isolated for new V5 page, this function is
// useless now, should be removed.
export const isV5page = () => hasPrefix(window.location.pathname, V5);

export const reEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i;

export const adminReEmail = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{1,4}|[0-9]{1,3})(\]?)$/;

export const phoneRegex = /[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*/;

export const sysadmin = 'sysadmin';

export function str2Int(id) {
	return parseInt(id, 10);
}

export const trimSlash = _trimSlash;

export const replaceString = (src, replacee, replacement) => src.replace(replacee, replacement);

export const trimPrefix = (src, prefix) => src && typeof src.slice === "function" && prefix.length ? src.slice(prefix.length) : src;

export const parseURL = url => {
	let urlObject;
	try {
		urlObject = new URL(url);
	} catch (error) {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: error parse URL:", {error});
		}
	}
	if (!urlObject) {
		let parser = document.createElement('a');
		parser.href = url;
		urlObject = {
			protocol: parser.protocol
			, hostname: parser.hostname
			, port: parser.port
			, pathname: parser.pathname
			, search: parser.search
			, hash: parser.hash
			, host: parser.host
		};
	}
	return urlObject;
}

// get file content with file path and return promise.
export function getFile(file) {
	const reader = new FileReader(), deferred = $.Deferred(), name = file.name;
	reader.onload = function() {
		const b64mark = ';base64,', r = reader.result, pos = r.indexOf(b64mark);
		if (pos < 0) {
			deferred.reject(new Error('invalid result: ' + name));
			return;
		}
		const dataPos = 'data:'.length
			, mime = r.substring(dataPos, pos)
			, content = r.substring(pos + b64mark.length)
			;
		deferred.resolve({name, mime, content});
	};
	reader.onloadend = function() {
		if (reader.error) {
			deferred.reject(new Error('file: '+name+' error: '+reader.error));
			return;
		}
	};
	reader.readAsDataURL(file);
	return deferred.promise();
}

// convert CSV string and return a copy of array and map even it is empty.
function csvStringToArrayAndMap(s, converter) {
	if (!s) {
		return [[], {}];
	}
	const array = s.split(",")
		, arrayOutput = []
		, mapOutput = {}
		;
	for (let i=0; i<array.length; i++) {
		const converted = converter(array[i]);
		arrayOutput.push(converted);
		mapOutput[converted] = true;
	}
	return [arrayOutput, mapOutput];
}

function csvStringToArrayBase(s, converter) {
	return csvStringToArrayAndMap(s, converter)[0];
}

const noConvert = s => s;

export const csvStringToArray = s => csvStringToArrayBase(s, noConvert);

export const csvStringToIntArray = s => csvStringToArrayBase(s, parseInt);

export const csvStringToIntArrayAndMap = s => csvStringToArrayAndMap(s, parseInt);

export function boolStringToBool(s) {
	if (s === "true") {
		return true;
	}
	return false;
}

const alwaysPassVerification = () => true;

function getInnerGroupIDsFromGroupedArrayBase(
	array
	, groupName
	, idField
	, verifier
) {
	if (!array || !array.length) {
		return emptyArray;
	}
	let ids = [];
	$.each(array, (i,v) => {
		const subgroup = v[groupName];
		if (subgroup) {
			$.each(subgroup, (j,w) => {
				if (verifier(w)) {
					ids.push(w[idField]);
				}
			});
		}
	});
	return ids;
}

export const getInnerGroupIDsFromGroupedArray = (
	array
	, groupName
	, idField
) => getInnerGroupIDsFromGroupedArrayBase(
	array
	, groupName
	, idField
	, alwaysPassVerification
);

export const allAreaIDsFromOrgAreas = areas => getInnerGroupIDsFromGroupedArray(
	areas
	, 'Areas'
	, 'Id'
);

export const orgAreasToAreaIDsString = (orgAreas, verifier) => {
	const array = getInnerGroupIDsFromGroupedArrayBase(
		orgAreas
		, 'Areas'
		, 'Id'
		, verifier
	);
	if (!array.length) {
		return "";
	}
	return array.join(',');
};

export function allOrgAreasToAreaIDsString(orgAreas) {
	return orgAreasToAreaIDsString(orgAreas, alwaysPassVerification);
}

export function isPhoneNumberService(map, service) {
	return service === map.SERVICE_VOICE
		|| service === map.SERVICE_SMS
		|| service === map.SERVICE_MANUAL_VOICE
		|| service === map.SERVICE_MANUAL_SMS;
}

export const hasQuery = url => url.indexOf("?") > -1

function wrapStartToEndRegexpToString(str) {
	return "^" + str + "$";
}

function wrapWithIgnoreWhiteSpace(str) {
	return "\\s*" + str + "\\s*";
}

function addCommaSeperatorRepeatableRegexpToString(str) {
	return str + "(\\s*,\\s*" + str + ")*";
}

function createCommaSeperatorMultiItemsRegex(reg, flags) {
	return new RegExp(wrapStartToEndRegexpToString(wrapWithIgnoreWhiteSpace(addCommaSeperatorRepeatableRegexpToString(reg.source))), flags);
}

function createStartToEndOneItemRegex(reg, flags) {
	return new RegExp(wrapStartToEndRegexpToString(wrapWithIgnoreWhiteSpace(reg.source)), flags);
}

// Secure message special user Id
const validOneFixedLengthNumericRegex = /^[0-9]{8}$/  // /^[0-9]*$/;
export const validFixedLengthNumericRegex = createStartToEndOneItemRegex(validOneFixedLengthNumericRegex);
export const validMultiFixedLengthNumericRegex = createCommaSeperatorMultiItemsRegex(validOneFixedLengthNumericRegex);
export const isValidFixedLengthNumeric = n => validFixedLengthNumericRegex.test(n);
export const isValidMultiFixedLengthNumeric = n => validMultiFixedLengthNumericRegex.test(n);

// NOTE: phone number regexp different from previous version (old than 5.0) as
// there isn't global flag use because global flag can not reuse the regexp as
// meantioned: https://stackoverflow.com/a/45528278/1778714
const validOnePhoneRegex = /[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*/;

export const validPhoneRegex = createStartToEndOneItemRegex(validOnePhoneRegex);

export const validMultiPhonesRegex = createCommaSeperatorMultiItemsRegex(validOnePhoneRegex);

export const isValidPhoneNo = phoneNo => validPhoneRegex.test(phoneNo);

export const isValidMultiPhones = phoneNo => validMultiPhonesRegex.test(phoneNo);

export const validSipRegex = /^sip:.+/;

export const isSipNumber = contact => validSipRegex.test(contact);

const validOneEmailRegex = /(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})/;

export const validMultiEmailsRegex = createCommaSeperatorMultiItemsRegex(validOneEmailRegex, "i");

export const validEmailRegex = createStartToEndOneItemRegex(validOneEmailRegex, "i");

export const isValidEmail = emailAddress => validEmailRegex.test(emailAddress);

export const isValidMultiEmails = emailAddress => validMultiEmailsRegex.test(emailAddress);

export const addressValidation = (type, address, multiple) => {
    let value = address.replace("\t", "").trim();
    if (type === Workflow.Errand.SERVICE_VOICE &&
        (isValidPhoneNo(value) || isSipNumber(value))) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_SMS &&
        isValidPhoneNo(value)) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_WHATSAPP &&
        hasPrefix(value, "wa-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_TWITTER &&
        hasPrefix(value, "twitter-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_FACEBOOK &&
        (hasPrefix(value, "facebook-") || value.includes("facebook.com"))) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_INSTAGRAM &&
        hasPrefix(value, "instagram-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_LINKEDIN &&
        hasPrefix(value, "linkedin-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_VKONTAKTE &&
        hasPrefix(value, "centionvk-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_LINE &&
        hasPrefix(value, "centionline-")) {
        return true;
    } else if ((type === Workflow.Errand.SERVICE_EMAIL ||
        type === Workflow.Errand.SERVICE_CHAT)) {
        if (!multiple && isValidEmail(value)) {
            return true;
        } else {
            if (type === Workflow.Errand.SERVICE_EMAIL) {
                if (isValidMultiEmails(value)) {
                    return true;
                }
            }
        }
    } else if (type === Workflow.Errand.SERVICE_CHAT_BOT) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_SLACK &&
        hasPrefix(value, "slack-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_TELEGRAM &&
        hasPrefix(value, "telegram-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_VIBER &&
        hasPrefix(value, "viber-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_TRUSTPILOT &&
        hasPrefix(value, "trustpilot-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_MICROSOFT_TEAMS &&
        hasPrefix(value, "teams-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_GOOGLE_REVIEW &&
        hasPrefix(value, "google-review-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_GOOGLE_CHAT &&
        hasPrefix(value, "google-chat-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_LINKEDIN_MANUAL &&
        hasPrefix(value, "linkedin-manual-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_SIP_VOICE &&
        hasPrefix(value, "sip-voice-")) {
        return true;
    } else if (type === Workflow.Errand.SERVICE_SIP_VIDEO &&
        hasPrefix(value, "sip-video-")) {
        return true;
    }
    return false;
};



export const emailValidation = email => addressValidation(Workflow.Errand.SERVICE_EMAIL, email);

const isBothArray = (v, w) => isArray(v) && isArray(w);

const checkArrayEqualityOnly = (v, w) => {
	return isBothArray(v, w) && isEqual(v, w);
};

const isObject = v => typeof v === "object"; // include Array

// checkObjectLike return if both v and w is considered as object type.
// If 'checkArray' true then array is considered as object type else it is not.
const checkObjectLike = (checkArray, v, w) => {
	return isObject(v) && isObject(w) && (checkArray || !isBothArray(v, w));
};

const isObjectOnlyAndNotArray = (v, w) => checkObjectLike(false, v, w)

const _changeMasksUpdaterBase = (src, changedSrc, maskCreator, checkArray) => {
	if (src === changedSrc) {
		return;
	}
	const change = {}
	const masks = []
	const updater = {}
	let changed = changedSrc
	each(src, (v, k) => {
		const w = changedSrc[k]
		changed = update(changed, { $unset: [k] })
		if (w === v) {
			return;
		} else if (!checkArray && checkArrayEqualityOnly(v, w)) {
			return;
		} else if (checkObjectLike(checkArray, v, w)) {
			const result = changeMasksUpdaterBase(
				v,
				w,
				field => maskCreator(k + '.' + field),
				checkArray
			);
			if (result) {
				const { change: _change, masks: _masks, updater: _updater } = result;
				if (_masks && _masks.length) {
					masks.push(..._masks);
					updater[k] = _updater;
					change[k] = _change;
				}
			}
			return;
		}
		change[k] = w;
		masks.push(maskCreator(k));
		updater[k] = { $set: w };
	});
	each(changed, (v, k) => {
		if (!v || !v.length) {
			// undefined, null or zero length consider as same
			return;
		}
		change[k] = v;
		masks.push(maskCreator(k));
		updater[k] = { $set: v };
	});
	if (masks.length <= 0) {
		return;
	}
	return { change, masks, updater };
};

const isObjectOrString = v => typeof v === 'object' || typeof v === 'string' || typeof v === 'undefined'

const changeMasksUpdaterBase = (
	src,
	changedSrc,
	maskCreator,
	equalityChecker,
	level
) => {
	if (src === changedSrc) {
		return;
	}
	const change = {}
	const masks = []
	const updater = {}
	let changed = changedSrc
	each(src, (v, k) => {
		const w = changedSrc[k]
		changed = update(changed, { $unset: [k] })
		const {
			markAsChanged,
			changedIsObjectLike
		} = equalityChecker(v, w, k, level)
		if (!markAsChanged) {
			return;
		} else if (changedIsObjectLike) {
			const result = changeMasksUpdaterBase(
				v,
				w,
				field => maskCreator(k + '.' + field),
				equalityChecker,
				level+1
			);
			if (result) {
				const { change: _change, masks: _masks, updater: _updater } = result;
				if (_masks && _masks.length) {
					masks.push(..._masks);
					updater[k] = _updater;
					change[k] = _change;
				}
			}
			return;
		}
		change[k] = w;
		masks.push(maskCreator(k));
		updater[k] = { $set: w };
	});
	each(changed, (v, k) => {
		if (isObjectOrString(v) && (!v || !v.length)) {
			// undefined, null or zero length consider as same
			return;
		}
		change[k] = v;
		masks.push(maskCreator(k));
		updater[k] = { $set: v };
	});
	if (masks.length <= 0) {
		return;
	}
	return { change, masks, updater };
};

const createNormalEqualityChecker = checkArray => (src, change) => {
	if (src === change || (!checkArray && checkArrayEqualityOnly(src, change))) {
		return emptyObject
	}
	return {
		markAsChanged: true,
		changedIsObjectLike: checkObjectLike(checkArray, src, change)
	}
}

const identity = v => v;

// 'checkArray' true mean array will be treated as object, that is each array
// element also count as like object key where key will be integer, which only
// can work when no addition or removal of array.
export const changeMasksUpdater = (
	src,
	change,
	checkArray
) => changeMasksUpdaterBase(
	src,
	change,
	identity,
	createNormalEqualityChecker(checkArray),
	0
);

export const simpleChange = (...args) => {
	const result = changeMasksUpdater(...args);
	if (result) {
		return result.change;
	}
};

// Array check only work is no addition or removal any of array element.
export const changedMasks = (src, change, checkArray) => {
	const result = changeMasksUpdater(src, change, checkArray);
	if (!result) {
		return;
	}
	return result.masks;
};

const markAsChanged = { markAsChanged: true }

const sortAndDeepEqualArray = (a, b) => {
	if (!isBothArray(a, b)) {
		return false
	}
	const _a = a.slice()
	const _b = b.slice()
	_a.sort()
	_b.sort()
	return isEqual(_a, _b)
}

const reCkeditorEmptyHTML = /^<div[^\/>]*>[\s\xa0]<\/div>\s*$/

const replaceEmptyStringIfEmptyHTML = s => {
	if (s && reCkeditorEmptyHTML.test(s)) {
		s = ''
	}
	return s
}

const isBothEmptyHTMLCkeditor = (
	a,
	b
) => !replaceEmptyStringIfEmptyHTML(a) && !replaceEmptyStringIfEmptyHTML(b)

const sortArrayEqualityCheck = (src, change) => {
	if (src === change || sortAndDeepEqualArray(src, change)) {
		return emptyObject
	}
	return {
		markAsChanged: true,
		changedIsObjectLike: checkObjectLike(false, src, change)
	}
}

const receiptGreetingChangeBase = (src, changeSrc) => changeMasksUpdaterBase(
	src,
	changeSrc,
	identity,
	(src, changeSrc, key, level) => {
		if (src === changeSrc) {
			return emptyObject
		} else if (level <= 0) {
			if (key === 'accounts') {
				// whole accounts data will be needed is any of the field changed.
				if (changeMasksUpdaterBase(src, changeSrc, identity,
					sortArrayEqualityCheck, 0)) {
					return markAsChanged
				}
				return emptyObject
			} else if (key === 'areas' || key === 'orgs') {
				if (sortAndDeepEqualArray(src, changeSrc)) {
					return emptyObject
				}
				return markAsChanged
			} else if (key === 'content') {
				if (isBothEmptyHTMLCkeditor(src, changeSrc)) {
					return emptyObject
				}
			}
		}
		return {
			markAsChanged: true,
			changedIsObjectLike: isObjectOnlyAndNotArray(src, changeSrc)
		}
	},
	0
)

export const receiptGreetingChange = (src, changeSrc) => {
	const result = receiptGreetingChangeBase(src, changeSrc)
	if (result) {
		return result.change
	}
}

const noYesArray = [I('No'), I('Yes')]

const yesOption = { id: 1, name: noYesArray[1] }

const noOption = { id: 0, name: noYesArray[0] }

export const noYesOption = [noOption, yesOption]

export const convertToYesOrNoId = value => value ? 1 : 0

export const convertToYesOrNo = value => noYesArray[convertToYesOrNoId(value)]

export const convertListToCommaSeperatedString = list => {
	if(list && list.length > 0){
		let dataList = [];
		for(let i = 0; i< list.length; i++){
			let data =  list[i];
			dataList.push(data.Id);
		}
		return dataList.join(',');
	}
	return "";
};

// https://stackoverflow.com/a/13627586/1778714
export function ordinal_suffix_of(i) {
	var j = i % 10,
		k = i % 100;
	if (j == 1 && k != 11) {
		return i + "st";
	}
	if (j == 2 && k != 12) {
		return i + "nd";
	}
	if (j == 3 && k != 13) {
		return i + "rd";
	}
	return i + "th";
}

export const isNumberOrString = value => {
	const t = typeof value
	return t === 'number' || t === 'string'
}

// eslint-disable-next-line eqeqeq
export const isNumberOrNumberStringEqual = (i, j) => i == j

export const detectObjectOnly = v => isObject(v) && !isArray(v)

export const receiptGreetingObject = (v, k, levels) => {
	if (!levels.length && k === 'accounts') {
		return false
	}
	return detectObjectOnly(v)
}

export const customMerger = (state, data, stepInChecker, levels = []) => {
	const _data = {}
	each(data, (v, k) => {
		if (stepInChecker(v, k, levels)) {
			let _state = state[k]
			if (typeof _state === 'undefined') {
				_state = {}
			}
			levels.push(k)
			_data[k] = customMerger(_state, v, stepInChecker, levels)
		} else {
			_data[k] = v
		}
	})
	return update(state, { $merge: _data })
}

// commonMerger merge data and step in object only to merge inner tree.
export const commonMerger = (
	state,
	data
) => customMerger(state, data, detectObjectOnly)

// autoMergeObject only merge content that exist on data into state.
export const autoMergeObject = (
	state,
	data
) => customMerger(state, data, isObject)

// https://stackoverflow.com/a/20732091/1778714
export function humanFileSize(size) {
	var i = Math.floor( Math.log(size) / Math.log(1024) );
	return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export const replaceEmptyArray = data => {
	if (!data || !data.length) {
		return emptyArray
	}
	return data
}

const reCSSUnit = /(px|cm|mm|in|q|pc|pt|%)$/i;

// cssUnitsInt converts css string value that has unit attached, strip it and
// turn into integer.
export const cssUnitsInt = value => {
	if(typeof value !== 'string'){
		return 0;
	}
	return parseInt(value.replace(reCSSUnit, ''), 10)
}

export const createServerChangeHandler = (
	condition,
	matchedConditionHost
) => host => {
	if (condition(host)) {
		host = matchedConditionHost
	}
	return host
}

// eslint-disable-next-line camelcase
export const nameFromOpenIdToken = ({ family_name, given_name, name }) => {
	let _name
	// eslint-disable-next-line camelcase
	if (family_name || given_name) {
		// eslint-disable-next-line camelcase
		_name = given_name + (given_name && family_name ? ' ' : '') +
			// eslint-disable-next-line camelcase
			family_name
	} else {
		_name = name
	}
	return _name
}

export const mouseClickCursor = disabled => {
	if (disabled) {
		return 'cursor: not-allowed; pointer-events: none;'
	}
	return 'cursor: pointer; pointer-events: auto;'
}
